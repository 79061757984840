import { PageProps } from 'gatsby';
import { FC, useContext } from 'react';
import tw, { css } from 'twin.macro';
import {
  ContentSection,
  footerLinkData,
  Hero,
  Layout,
  LI,
  LinkPlus,
  links,
  SEO,
  UL,
} from '~/components';
import { Frontmatter } from '~/config';
import { IndicationsContext } from '~/context';
import { usePagesQuery } from '~/hooks';
import heroBackground from '~/images/hero-laptop.jpg';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Site Map',
  order: 110,
  screenShoot: true,
};

const Page: FC<PageProps> = (props) => {
  const data = usePagesQuery();
  const { toggleIrritator: toggleIndications } = useContext(IndicationsContext);

  return (
    <Layout>
      <SEO
        title="For healthcare professionals │ NERLYNX® (neratinib) tablets"
        description="NERLYNX is indicated for the treatment of early-stage and metastatic HER2+ breast cancer. See recurrence and progression rates data."
        ogDescription="Learn about NERLYNX treatment for early-stage and metastatic HER2+ breast cancer."
        path={props.location?.pathname}
      />
      <Hero
        textLeft
        halfHeight
        backgroundImage={heroBackground}
        backgroundStyle={tw`bg-cover background-position-x[right]`}
        characterWidth={50}
        titleStyle={tw`mt-0 md:(mt-2)`}
      >
        Site map
      </Hero>
      <ContentSection customCss={tw`pb-12 lg:pb-16`}>
        <UL
          noBullets
          css={css`
            li {
              margin: 0.5rem 1rem 0.5rem 0;
            }
          `}
        >
          <LI>
            <LinkPlus to={links.patients} customCss={mainItemCss}>
              For Patients
            </LinkPlus>
          </LI>
          <LI>
            <LinkPlus onClick={toggleIndications} customCss={mainItemCss}>
              Indications
            </LinkPlus>
          </LI>
          <LI>
            <LinkPlus to="/isi/" customCss={mainItemCss}>
              Important Safety Information
            </LinkPlus>
          </LI>
          <LI>
            <LinkPlus to={links.pi} customCss={mainItemCss}>
              Prescribing Information
            </LinkPlus>
          </LI>
          <LI>
            <LinkPlus to="/request-information/" customCss={mainItemCss}>
              Request Information
            </LinkPlus>
          </LI>

          {data.map((item) => {
            return (
              <LI key={item.slug}>
                <LinkPlus to={item.to} customCss={mainItemCss}>
                  {item.desktopNavTitle?.replace(/\\n/g, ' ') ?? item.title}
                </LinkPlus>
                {item.anchors && (
                  <UL noBullets customCss={tw`ml-4 mb-0`}>
                    {item.anchors.map((anchor) => (
                      <LI key={anchor.id}>
                        <LinkPlus to={anchor.to} customCss={anchorItemCss}>
                          {anchor.sitemapLabel ?? anchor.label}
                        </LinkPlus>
                      </LI>
                    ))}
                  </UL>
                )}
              </LI>
            );
          })}
        </UL>
        <div tw="height[1px] max-w-xs ml-0 bg-brand-black mb-6" />
        <UL
          noBullets
          css={css`
            li {
              margin: 0.5rem 1rem 0.5rem 0;
            }
          `}
        >
          {footerLinkData
            .filter((l) => l.label !== 'Site map')
            .map((item) => (
              <LI key={item.label}>
                <LinkPlus to={item.to} customCss={mainItemCss}>
                  {item.label}
                </LinkPlus>
              </LI>
            ))}
        </UL>
      </ContentSection>
    </Layout>
  );
};

const mainItemCss = tw`uppercase no-underline font-medium text-brand-dark-blue text-lg leading-8`;
const anchorItemCss = tw`no-underline font-medium text-brand-cyan text-xs leading-6`;

export default Page;
